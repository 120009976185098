import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Loader from './Loader';
import { ColourLevel } from '../types';
import theme from '../utils/theme';

const StyledButton = styled.button<{
  $outlineColour?: string;
  $backgroundColour?: string;
  $textColour?: string;
  $height: string;
  $fluid?: boolean;
}>`
  font-family: ${({ theme }) => theme.font.families.body};
  font-weight: 700;
  font-size ${({ theme }) => theme.font.sizes.lg};

  background: ${({ $backgroundColour }) => $backgroundColour};
  width: ${({ $fluid }) => ($fluid ? '100%' : 'auto')};
  color: ${({ $textColour }) => $textColour};
  height: ${({ $height }) =>
    $height === 'auto' ? theme.sizes.formControlHeight : $height};
  padding: ${({ theme }) => theme.sizes.lvl0} ${({ theme }) =>
  theme.sizes.lvl6};
  position: relative;
  border: ${({ $outlineColour }) => `3px ${$outlineColour} solid`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.5s;
    border-radius: ${({ theme }) => theme.sizes.borderRadius};
  }
  :hover,
  :focus {
    outline: 0;
    &:after {
      opacity: ${({ disabled }) => (disabled ? 0 : 1)};
    }
  }
`;

const MaterialIcon = styled.span`
  margin-right: ${({ theme }) => theme.sizes.lvl1};
`;

enum ButtonVariant {
  FILLED = 'FILLED',
  FILLEDDARKER = 'FILLEDDARKER',
  OUTLINE = 'OUTLINE',
}

interface Button {
  children: ReactNode;
  className?: string;
  onClick?: Function;
  disabled?: boolean;
  fluid?: boolean;
  height?: string;
  variant?: ButtonVariant;
  colourLevel?: ColourLevel;
  icon?: ReactNode;
  loading?: boolean;
  outlineColour?: string;
  textColour?: string;
  backgroundColour?: string;
  [x: string]: any;
}

const Button = ({
  children,
  className,
  onClick = () => {},
  disabled,
  fluid,
  height = 'auto',
  variant = ButtonVariant.FILLED,
  colourLevel = ColourLevel.PRIMARY,
  icon,
  loading,
  outlineColour,
  textColour,
  backgroundColour,
  ...otherProps
}: Button) => {
  const bgColour =
    backgroundColour || theme.colourLevels[colourLevel].background[variant];
  const outColour =
    outlineColour || theme.colourLevels[colourLevel].outline[variant];
  const txtColour = textColour || theme.colourLevels[colourLevel].text[variant];

  return (
    <StyledButton
      className={className}
      onClick={(e) => onClick(e)}
      disabled={disabled}
      $backgroundColour={bgColour}
      $outlineColour={outColour}
      $textColour={txtColour}
      $fluid={fluid}
      $height={height}
      {...otherProps}
    >
      {icon && (
        <MaterialIcon className="material-icons" style={{ color: txtColour }}>
          {icon}
        </MaterialIcon>
      )}

      {children}

      {loading && <Loader size={32} color={txtColour} />}
    </StyledButton>
  );
};

export { Button, ButtonVariant };
export default Button;
