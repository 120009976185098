import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Container from '../layout/Container';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.black};
`;

const StyledContainer = styled(Container)`
  padding-bottom: ${({ theme }) => theme.sizes.lvl3};
`;

const SponsorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: ${({ theme }) => theme.sizes.lvl7};
  padding-bottom: ${({ theme }) => theme.sizes.lvl6};
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    padding-bottom: ${({ theme }) => theme.sizes.lvl10};
    flex-direction: row;
  `}
`;

const LogoContainer = styled.div`
  width: 50%;
  margin: 0 auto ${({ theme }) => theme.sizes.lvl6} auto;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    width: 100%;
    max-width: 256px;

    padding: 0px ${({ theme }) => theme.sizes.lvl3};
    margin: 0px;
  `}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    white-space: nowrap;
  `}
`;

const Title = styled.div`
  && {
    font-size: ${({ theme }) => theme.font.sizes.sm};
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
    padding: 0px;
    margin: 0px;
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      white-space: nowrap;
  `}
  }
`;

const SponsorsSection = () => {
  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "Sponsors" }, data: { Name: { ne: null } } }
      ) {
        edges {
          node {
            data {
              Name
              Logo {
                localFiles {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `);

  const sponsors = allAirtable?.edges?.map((edge) => edge?.node) || [];

  return (
    <Background>
      <StyledContainer>
        <Title className="h4">PROUDLY PART OF ALEX HOWARD GROUP</Title>
        <SponsorsContainer>
          {sponsors.map((sponsor) => (
            <LogoContainer key={`logo_${sponsor.id}`}>
              <Img
                fluid={
                  sponsor.data?.Logo?.localFiles[0]?.childImageSharp?.fluid
                }
              />
            </LogoContainer>
          ))}
        </SponsorsContainer>
      </StyledContainer>
    </Background>
  );
};

export default SponsorsSection;
